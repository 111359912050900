import React from "react"
import CommonHeader from "../components/dpcgc/CommonHeader"

import { Box, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Footer from "../components/dpcgc/Footer"
// import whoweare from "../images/whoweare.svg"
import whoweare from "../images/whoweare.jpg"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },

    // background: "#243974",
  },

  bigheading: {
    // width:"150px",
    // height:"30px",
    FontFace: "Roboto",
    fontSize: "28px",
    lineHeight: "33px",
    color: "#243974",
    marginleft: "334px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
  },

  content: {
    marginTop: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },

  text: {
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4F4F4F",
    FontFace: "Roboto",
    textAlign: "justify",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "22px",
    },

  },

  rafiki: {
    marginTop: "5px",
    width:"100%",
    // position: "absolute",
    height:"50rem",

    [theme.breakpoints.down("sm")]: {
      height: "40rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "40rem",
    },
    [theme.breakpoints.down("md")]: {
      height: "30rem",
    },
  },
 
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    textTransform: "capitalize",
    color:"#333333"


  },
}))

export default function Whoweare() {
  const classes = useStyles()
  return (
    <>
      <CommonHeader />
      <Box height="10rem" />
      <Box className={classes.root}>
        <Grid container justifyContent="center">
          <Grid item sm={8} xs={9} md={6}>
            <Typography className={classes.bigheading}>About Us</Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item sm={8} xs={9} md={6}>
            <Box className={classes.content}>
              <Grid container>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "center" }}
                >
                  <Box height="5rem" />

                  <Grid item sm={7} xs={8} md={7} style ={{textAlign:"left"}}>
                    <Typography className={classes.heading}>Who We Are</Typography>
                  </Grid>

                  <Box height="2rem" />
                  <Typography className={classes.text}>
                    Digital Publisher Content Grievances Council (DPCGC) is an
                    officially recognized, independent self-regulatory body for
                    online curated content providers (OCCPs). It has been set up
                    under the aegis of Internet and Mobile Association of India
                    (IAMAI), in June 2021.
                  </Typography>
                  <Box height="2rem" />
                  <Typography className={classes.text}>
                    DPCGC is registered by the Ministry of Information &
                    Broadcasting as a Level-II Self-Regulatory Body for
                    publishers of online curated content, under the Information
                    Technology (Intermediary Guidelines and Digital Media Ethics
                    Code) Rules, 2021.
                  </Typography>
                  <Box height="2rem" />
                  <Typography className={classes.text}>
                    It adheres to IT Rules 2021 and such other existing or new
                    statutes, and rules/regulations/guidelines framed thereunder
                    from time to time, relating to publishing of online curated
                    content.
                  </Typography>
                  <Box height="5rem" />
                  <Grid item sm={8} xs={9} md={7} style ={{textAlign:"left"}}>
                    <Typography className={classes.heading}>Purpose and Vision</Typography>
                  </Grid>
                  <Box height="2rem" />
                  <Typography className={classes.text}>
                    DPCGC, through the Grievance Redressal Board (GRB), DPCGC aims to
                    usher in a redressal mechanism which ensures a balance
                    between addressing viewer complaints fairly and showcasing
                    content without ad-hoc interventions.
                  </Typography>
                  <Box height="2rem" />
                  <Typography className={classes.text}>
                    DPCGC acknowledges that the impact of online curated content
                    in the lives of Indian citizens is deep and pervasive, owing
                    to the various ways in which it impacts and influences
                    people. Encouraging OCCPs to introduce greater specificity
                    and detail into content published by them, DPCGC intends to
                    facilitate self-regulation by the industry and minimize
                    scope for subjective decision-making in addressing the
                    grievances of consumers.
                  </Typography>
                  <Box height="2rem" />
                  <Typography className={classes.text}>
                    DPCGC seeks to ensure that the responsibility of
                    the GRB must be discharged with full commitment to official
                    regulations and a considered understanding of the content
                    that is permitted or prohibited under various laws of the
                    land.
                  </Typography>

                  {/* </Typography> */}
                </Grid>
              </Grid>
            </Box>

            <Box height="4rem" />
            <Grid item md={12} sm={12} xs={12} >
              <img src={whoweare} className={classes.rafiki} />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box height="10rem" />
      <Footer />
    </>
  )
}
